import "./RailroadTracks.css";

const RailroadTracks = ({ stops, trains, direction }) => {
  const stopTypes = ["local", "limited", "express", "southcounty" ];

  // Determine the horizontal alignment of trains
  const getLinePosition = (trainId) => {
    if (trainId.startsWith("1") || trainId.startsWith("6")) return "local";
    if (trainId.startsWith("4")) return "limited";
    if (trainId.startsWith("5")) return "express";
    if (trainId.startsWith("8")) return "southcounty";
    return "local"; // Default to "local" if no match
  };

  // Determine the triangle direction (up or down)
  const getTriangleDirection = (trainId) =>
    parseInt(trainId, 10) % 2 === 0 ? "southbound" : "northbound";

  // Calculate the position of the train between two stops
  const calculateTrainPosition = (train, stops) => {
    const trainLat = Number(train.lat);

    // Find the two stops the train is between
    let previousStop = null;
    let nextStop = null;

    let topBuffer = 2.5;
    let bottomBuffer = 4;

    for (let i = 0; i < stops.length - 1; i++) {
      if (
        (trainLat >= stops[i].stop_lat && trainLat <= stops[i + 1].stop_lat) ||
        (trainLat <= stops[i].stop_lat && trainLat >= stops[i + 1].stop_lat)
      ) {
        previousStop = stops[i];
        nextStop = stops[i + 1];
        break;
      }
    }

    if (!previousStop || !nextStop) {
      return null; // Train is out of bounds
    }

    // Interpolate position between the two stops
      const totalDistance = Math.abs(nextStop.stop_lat - previousStop.stop_lat);
      const distanceFromPreviousStop = Math.abs(trainLat - previousStop.stop_lat);
      const positionPercentage =
        (distanceFromPreviousStop / totalDistance) * 100;

      // Determine the absolute position from the top, including buffers
      const previousStopPosition =
        topBuffer + ((stops.indexOf(previousStop) / (stops.length - 1)) * (100 - topBuffer - bottomBuffer));
      const nextStopPosition =
        topBuffer + ((stops.indexOf(nextStop) / (stops.length - 1)) * (100 - topBuffer - bottomBuffer));

      const interpolatedPosition =
        previousStopPosition +
        ((nextStopPosition - previousStopPosition) * positionPercentage) / 100;

      return interpolatedPosition;
  };

  
  return (
    <div className={`track-container ${direction.toLowerCase()}`}>
      <div className="track">
        {/* Line container for vertical lines */}
          <div className="line-container">
            {stopTypes.map((type) => {
              const isSouthcounty = type === "southcounty";
              const diridonIndex = stops.findIndex((stop) => stop.stop_name === "Diridon");

              // if (diridonIndex === -1) {
              //   console.error("Tamien not found in stops list!");
              // } else {
              //   console.log("Diridon index:", diridonIndex);
              // }

              const tamienPosition =
                diridonIndex >= 0
                  ? (diridonIndex / (stops.length - 1)) * 96
                  : 0; // Calculate Tamien's position as a percentage

              // console.log(
              //   `Processing line type: ${type}, isSouthcounty: ${isSouthcounty}, tamienPosition: ${tamienPosition}%`
              // );

              return (
                <div
                  key={type}
                  className={`line ${type}`}
                  style={
                    isSouthcounty
                      ? {
                          "--start-position": `${tamienPosition}%`,
                        }
                      : {}
                  }
                ></div>
              );
            })}
          </div>

        {/* Train Indicators */}
        {trains
          .map((train) => {
            
            const linePosition = getLinePosition(train.trainId); // Determine the line
            const triangleDirection = getTriangleDirection(train.trainId); // Determine the direction
            const interpolatedPosition = calculateTrainPosition(train, stops);

            if (interpolatedPosition === null) return null; // Skip rendering if out of bounds

            const leftPosition = 16 + stopTypes.indexOf(linePosition) * 25 - 25;

            return (
              <div
                key={train.trainId}
                className={`train-icon ${triangleDirection}`}
                style={{
                  top: `${interpolatedPosition}%`, // Interpolated position between two stops
                  left: `${leftPosition}px`, // Align to the appropriate line
                }}
              >
                <span className="train-number">{train.trainId}</span>
              </div>
            );
          })
          .filter(Boolean) /* Remove null values */}
        {/* Stops */}
        {stops.map((stop, index) => (
          <div key={index} className="stop-item">
            {stopTypes.map((type) => {
              const hasType = (stop.scheduleTypes || [])
                .map((t) => t.toLowerCase())
                .includes(type);
              return (
                <div
                  key={type}
                  className={`stop ${hasType ? type : "blank"}`}
                ></div>
              );
            })}
            {/* Stop Name */}
            <span className="stop-name">{stop.stop_name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RailroadTracks;