import React from "react";

const Footer = ({ updated }) => {
  // Check the user-agent for Mac, iPad, or iPhone

  return (
    <footer>
      <p>
        Updated: {updated}.{" "}
        <a href="https://www.linquist.com/tech/caltrain/">How this is calculated</a>.{" "}
        Contact: better@caltrain.live.
      </p>      
    </footer>
  );
};

export default Footer;