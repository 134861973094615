/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import stationsData from "../data/stationsData.json"; // Replace with actual station data
import schedulesData from "../data/trainSchedule.json"; // Replace with train schedule data
import "./TripPlanner.css"; // Add your styles here



const TicketPrices = {
    prices: {
        adult: {
            1: 3.75, 2: 6.00, 3: 8.25, 4: 10.50, 5: 12.75, 6: 15.00
        },
        adultClipper: {
            1: 3.20, 2: 5.45, 3: 7.70, 4: 9.95, 5: 12.20, 6: 14.45
        },
        discounted: {
            1: 1.75, 2: 2.75, 3: 3.75, 4: 4.75, 5: 5.75, 6: 6.75
        },
        discountedClipper: {
            1: 1.60, 2: 2.60, 3: 3.60, 4: 4.60, 5: 5.60, 6: 6.60
        },
        youth: {
            1: 1.00, 2: 1.00, 3: 1.00, 4: 1.00, 5: 1.00, 6: 1.00
        },
        monthly: {
            1: 76.80, 2: 130.80, 3: 184.80, 4: 238.78, 5: 292.80, 6: 346.80
        },
        monthlyDiscount: {
            1: 38.40, 2: 62.40, 3: 86.40, 4: 110.40, 5: 134.40, 6: 158.40
        }
    },

    getPricesForZones: function (zones) {
        const prices = this.prices;
        const results = [];

        if (prices.adult[zones] !== undefined) {
            results.push({ type: "Adult", price: prices.adult[zones] });
        }
        if (prices.adultClipper[zones] !== undefined) {
            results.push({ type: "Adult (Clipper)", price: prices.adultClipper[zones] });
        }
        if (prices.discounted[zones] !== undefined) {
            results.push({ type: "Discounted", price: prices.discounted[zones] });
        }
        if (prices.discountedClipper[zones] !== undefined) {
            results.push({ type: "Discounted (Clipper)", price: prices.discountedClipper[zones] });
        }
        if (prices.youth[zones] !== undefined) {
            results.push({ type: "Youth", price: prices.youth[zones] });
        }
        if (prices.monthly[zones] !== undefined) {
            results.push({ type: "Monthly Pass", price: prices.monthly[zones] });
        }
        if (prices.monthlyDiscount[zones] !== undefined) {
            results.push({ type: "Monthly Pass (Discount/Youth)", price: prices.monthlyDiscount[zones] });
        }

        return results;
    }
};



const TripPlanner = () => {
  const { originId, destinationId } = useParams(); // Extract IDs from the URL
  const navigate = useNavigate(); // For updating the URL  
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [isPricesExpanded, setIsPricesExpanded] = useState(false); // State for ticket prices expansion
  const [prices, setPrices] = useState([]); // Store calculated prices
  
  const [dayType, setDayType] = useState(() => {
    const isWeekend = [0, 6].includes(new Date().getDay());
    return isWeekend ? "weekend" : "weekday";
  });

  const [availableTrains, setAvailableTrains] = useState([]);
  const [showSnarkyMessage, setShowSnarkyMessage] = useState(false);
  const trainRefs = useRef([]); // Store refs for each train item

  useEffect(() => {
    // Set stations based on URL params if available
    if (originId) {
      const originStation = stationsData.find(
        (station) => station.stop_id === Number(originId)
      );
      setSelectedOrigin(originStation);
    }
    if (destinationId) {
      const destinationStation = stationsData.find(
        (station) => station.stop_id === Number(destinationId)
      );
      setSelectedDestination(destinationStation);
    }
  }, [originId, destinationId]);
  


  useEffect(() => {
    console.log('pageload')
    // Update URL whenever origin or destination changes
    if (selectedOrigin && selectedDestination) {
      const zones = Math.abs(selectedOrigin.zone - selectedDestination.zone) + 1
      const prices = TicketPrices.getPricesForZones(zones);
      setPrices(prices);      
      navigate(
        `/planner/${selectedOrigin.stop_id}/${selectedDestination.stop_id}`
      );
      findTrains(selectedOrigin, selectedDestination, dayType);
    } else if (selectedOrigin) {
      navigate(`/planner/${selectedOrigin.stop_id}`);
    } else if (selectedDestination) {
      navigate(`/planner/unknown/${selectedDestination.stop_id}`);
    } else {
      navigate("/planner");
    }
  }, [selectedOrigin, selectedDestination, dayType, navigate]);  

  const togglePrices = () => setIsPricesExpanded(!isPricesExpanded);
  
    // Automatically fetch location on page load
  useEffect(() => {
    if (!originId) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const nearestStation = stationsData.reduce((nearest, station) => {
              const distance = Math.sqrt(
                Math.pow(station.stop_lat - latitude, 2) +
                Math.pow(station.stop_lon - longitude, 2)
              );
              return distance < nearest.distance
                ? { station, distance }
                : nearest;
            }, { station: null, distance: Infinity }).station;

            setSelectedOrigin(nearestStation);
          },
          (error) => {
            console.error("Geolocation error:", error);
            alert("Unable to determine location. Please enable location services.");
          }
        );
      } else {
        alert("Geolocation is not supported by your browser.");
      }
    }
  }, []); // Run on page load


  const formatTime = (time) => {
    if (time === "N/A") return time;
    const [hours, minutes] = time.split(":").map(Number);
    const ampm = hours >= 12 && hours < 24 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  };

  const calculateDuration = (departureTime, arrivalTime) => {
    if (departureTime === "N/A" || arrivalTime === "N/A")
      return "Unknown duration";
    const [depHours, depMinutes] = departureTime.split(":").map(Number);
    const [arrHours, arrMinutes] = arrivalTime.split(":").map(Number);

    const depTimeInMinutes = depHours * 60 + depMinutes;
    const arrTimeInMinutes = arrHours * 60 + arrMinutes;

    const durationMinutes = arrTimeInMinutes - depTimeInMinutes;
    return `${durationMinutes} minutes`;
  };

  useEffect(() => {
    const isWeekend = [0, 6].includes(new Date().getDay());
    setDayType(isWeekend ? "weekend" : "weekday");
  }, []);

  useEffect(() => {
    if (selectedOrigin && selectedDestination) {
      findTrains(selectedOrigin, selectedDestination, dayType);
    } else {
      setShowSnarkyMessage(true);
      setAvailableTrains([]);
    }
  }, [selectedOrigin, selectedDestination, dayType]);

const findTrains = (origin, destination, dayType) => {
  if (origin === destination) {
    setShowSnarkyMessage(true);
    setAvailableTrains([]);
    return;
  }
  setShowSnarkyMessage(false);

  const isNorthbound = origin.stop_lat < destination.stop_lat;
  const adjustedOriginStopId = isNorthbound ? origin.stop_id : origin.stop_id + 1;
  const adjustedDiridon = isNorthbound ? 70261 : 70262;
  const adjustedDestinationStopId = isNorthbound
    ? destination.stop_id
    : destination.stop_id + 1;

  const isSouthCountyTrip =
    (origin.stop_id > 70273 || destination.stop_id > 70273) &&
    dayType !== "weekend";

  const filteredSchedules = schedulesData.filter((schedule) => {
    const isWeekendTrain = schedule.trip_id.startsWith("6");
    const matchesDayType = dayType === "weekend" ? isWeekendTrain : !isWeekendTrain;
    const isCorrectDirection = isNorthbound
      ? schedule.trip_id % 2 !== 0
      : schedule.trip_id % 2 === 0;

    return matchesDayType && isCorrectDirection;
  });

  const trainsByTripId = filteredSchedules.reduce((acc, schedule) => {
    acc[schedule.trip_id] = acc[schedule.trip_id] || [];
    acc[schedule.trip_id].push(schedule);
    return acc;
  }, {});

  let results = [];

  if (isSouthCountyTrip) {
    // Handle South County Trips
    Object.keys(trainsByTripId).forEach((trainId) => {
      const stops = trainsByTripId[trainId];
      const departureStop = stops.find((s) => s.stop_id === adjustedOriginStopId);
      const arrivalAtDiridon = stops.find((s) => s.stop_id === adjustedDiridon);

      if (departureStop && arrivalAtDiridon) {
        const transferTrainId = getTransferForTrainID(
          trainId,
          isNorthbound,
          destination,
          origin
        );
        
        //console.log("Checking South County Train:", trainId, "Transfer Train:", transferTrainId);

        if (transferTrainId) {
          const transferTrainStops = trainsByTripId[transferTrainId];
          const transferDeparture = transferTrainStops.find((s) => s.stop_id === adjustedDiridon);
          const finalArrival = transferTrainStops.find(
            (s) => s.stop_id === adjustedDestinationStopId
          );

          if (transferDeparture && finalArrival) {


            let departureStopName = stationsData.find(
              (station) =>
                station.stop_id === adjustedOriginStopId || station.stop_id === origin.stop_id
            )?.stop_name || "Unknown";

            const arrivalStopName = stationsData.find(
                (station) =>
                  station.stop_id === finalArrival.stop_id || station.stop_id === destination.stop_id
              )?.stop_name || "Unknown";


            results.push([
              {
                trainId,
                departureTime: departureStop.departure_time,
                arrivalTime: arrivalAtDiridon.arrival_time,
                departureStopName,
                arrivalStopName: 'Diridon',
                stops: stops.map((stop) =>
                  stationsData.find((station) => isNorthbound ? station.stop_id === stop.stop_id : station.stop_id + 1 === stop.stop_id)
                ),
              },
              {
                trainId: transferTrainId,
                departureTime: transferDeparture.departure_time,
                arrivalTime: finalArrival.arrival_time,
                departureStopName: 'Diridon',
                arrivalStopName,
                stops: transferTrainStops.map((stop) =>
                  stationsData.find((station) => isNorthbound ? station.stop_id === stop.stop_id : station.stop_id + 1 === stop.stop_id )
                ),
              },
            ]);
          }
        }
      }
    });
  } else {
    // Handle Direct Trains
    Object.keys(trainsByTripId).forEach((trainId) => {
      const stops = trainsByTripId[trainId];
      const departureStop = stops.find((s) => s.stop_id === adjustedOriginStopId);
      const arrivalStop = stops.find((s) => s.stop_id === adjustedDestinationStopId);


      if (departureStop && arrivalStop) {

        const departureStopName = stationsData.find(
              (station) =>
                station.stop_id === adjustedOriginStopId || station.stop_id === origin.stop_id
            )?.stop_name || "Unknown";
        const arrivalStopName = stationsData.find(
            (station) =>
              station.stop_id === adjustedDestinationStopId || station.stop_id === destination.stop_id
          )?.stop_name || "Unknown";

        results.push([
          {
            trainId,
            departureTime: departureStop.departure_time,
            arrivalTime: arrivalStop.arrival_time,
            departureStopName,
            arrivalStopName,
            stops: stops.map((stop) =>
              stationsData.find((station) => station.stop_id === stop.stop_id)
            ),
          },
        ]);
      }
    });
  }

  // Sort results by first train departure time
  results.sort((a, b) => {


    let aTime, bTime;

    if (Number(a[0].departureTime.split(":")[0] >= 24)) {
      const [hours, minutes, seconds] = a[0].departureTime.split(":").map(Number);
      const adjustedHours = hours - 24;
      aTime = new Date(`1970-01-02T${formatToISOTime(`${adjustedHours}:${minutes}:${seconds}`)}Z`).getTime();
    } else {
      aTime = new Date(`1970-01-01T${formatToISOTime(a[0].departureTime)}Z`).getTime();
    }

    if (Number(b[0].departureTime.split(":")[0] >= 24)) {
      const [hours, minutes, seconds] = b[0].departureTime.split(":").map(Number);
      const adjustedHours = hours - 24;
      bTime = new Date(`1970-01-02T${formatToISOTime(`${adjustedHours}:${minutes}:${seconds}`)}Z`).getTime();
    } else {
      bTime = new Date(`1970-01-01T${formatToISOTime(b[0].departureTime)}Z`).getTime();
    }
    if (isNaN(aTime)) {
      console.log('aTime Invalid time detected:', a[0]);
    } 
    if (isNaN(aTime)) {
      console.log('aTime Invalid time detected:', a[0]);
    } 
    
    return aTime - bTime;
  });

  //console.log('Setting available trains: ', results.map((trainGroup) => trainGroup.map((train) => train.trainId)));

  setAvailableTrains(results);
};

  const formatToISOTime = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    const paddedHours = hours.toString().padStart(2, "0");
    return `${paddedHours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  
  const getTransferForTrainID = (trainID, isNorthbound, destination, origin) => {
    if (isNorthbound) {
      switch (trainID) {
        case "805":
          //console.log("Checking 805", destination.scheduleTypes);
          if (destination.scheduleTypes.includes("Limited")) {
            return "405";
          } else if (destination.scheduleTypes.includes("Local")) {
            return "109";
          }
          break;
        case "807":
          // console.log("Checking 807", destination.scheduleTypes);
          if (destination.scheduleTypes.includes("Express")) {
            return "507";
          } else if (
            destination.scheduleTypes.includes("Limited") ||
            destination.scheduleTypes.includes("Local")
          ) {
            return "111";
          }
          break;
        case "809":
          if (destination.scheduleTypes.includes("Limited")) {
            return "409";
          } else if (
            destination.scheduleTypes.includes("Limited") ||
            destination.scheduleTypes.includes("Local")
          ) {
            return "113";
          }
          break;
        case "811":
          if (destination.scheduleTypes.includes("Express")) {
            return "511";
          } else if (
            destination.scheduleTypes.includes("Local") ||
            destination.scheduleTypes.includes("Limited")
          ) {
            return "115";
          }
          break;
        default:
          return null;
      }
    } else {
      switch (trainID) {
        case "140":
          if (
            origin.scheduleTypes.includes("Local") ||
            origin.scheduleTypes.includes("Limited")
          ) {
            return "814";
          }
          break;
        case "514":
          if (origin.scheduleTypes.includes("Express")) {
            return "814";
          }
          break;
        case "142":
          if (origin.scheduleTypes.includes("Local")) {
            return "816";
          }
          break;
        case "416":
          if (origin.scheduleTypes.includes("Limited")) {
            return "816";
          }
          break;
        case "146":
          if (origin.scheduleTypes.includes("Local")) {
            return "820";
          }
          break;
        case "420":
          if (origin.scheduleTypes.includes("Limited")) {
            return "820";
          }
          break;
        case "148":
          if (
            origin.scheduleTypes.includes("Local") ||
            origin.scheduleTypes.includes("Limited")
          ) {
            return "822";
          }
          break;
        case "522":
          if (origin.scheduleTypes.includes("Express")) {
            return "822";
          }
          break;
        default:
          return null;
      }
    }
    return null;
  };

    const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setIsModalOpen(false);
  };

  const jumpToNextTrain = () => {
    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();

    // Find the next train based on departure time
    const nextTrainIndex = availableTrains.findIndex((trainGroup) => {
      const [hours, minutes] = trainGroup[0].departureTime.split(":").map(Number);
      const trainTimeInMinutes = hours * 60 + minutes;
      return trainTimeInMinutes > currentMinutes;
    });

    if (nextTrainIndex !== -1) {
      // Scroll to the next train
      trainRefs.current[nextTrainIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      // Highlight the train temporarily
      const trainElement = trainRefs.current[nextTrainIndex];
      if (trainElement) {
        trainElement.classList.add("highlight");
        setTimeout(() => trainElement.classList.remove("highlight"), 2000);
      }
    } else {
      alert("No upcoming trains found!");
    }
  };


  


  return (
    <div className="trip-planner">
      <div className="controls">
        <div className="day-type-selector">
          <button
            className={`day-type-button ${
              dayType === "weekday" ? "active" : ""
            }`}
            onClick={() => setDayType("weekday")}
          >
            Weekday
          </button>
          <button
            className={`day-type-button ${
              dayType === "weekend" ? "active" : ""
            }`}
            onClick={() => setDayType("weekend")}
          >
            Weekend
          </button>
        </div>

        <div className="station-selectors">
          <select
            value={selectedOrigin?.stop_id || ""}
            onChange={(e) =>
              setSelectedOrigin(
                stationsData.find(
                  (station) => station.stop_id === Number(e.target.value)
                )
              )
            }
          >
            <option value="" disabled>
              Select Origin Station
            </option>
            {stationsData.map((station) => (
              <option key={station.stop_id} value={station.stop_id}>
                {station.stop_name}
              </option>
            ))}
          </select>
          <button
            className="swap-button"
            onClick={() => {
              const temp = selectedOrigin;
              setSelectedOrigin(selectedDestination);
              setSelectedDestination(temp);
            }}
          >
            ↔
          </button>
          <select
            value={selectedDestination?.stop_id || ""}
            onChange={(e) =>
              setSelectedDestination(
                stationsData.find(
                  (station) => station.stop_id === Number(e.target.value)
                )
              )
            }
          >
            <option value="" disabled>
              Select Destination Station
            </option>
            {stationsData.map((station) => (
              <option key={station.stop_id} value={station.stop_id}>
                {station.stop_name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="button-row">
        {/* Jump to Next Train Button */}
        {availableTrains.length > 0 &&
          dayType === (new Date().getDay() === 0 || new Date().getDay() === 6 ? "weekend" : "weekday") && (
            <button className="jump-button" onClick={jumpToNextTrain}>
              Jump to Next Train
            </button>
          )}

        {/* Show Ticket Prices Button */}
        {selectedOrigin && selectedDestination && (
          <button className="jump-button" onClick={togglePrices}>
            {isPricesExpanded ? "Hide Ticket Prices" : "Show Ticket Prices"}
          </button>
        )}
      </div>

      {/* Ticket Prices Table */}
      {isPricesExpanded && (
        <div className="prices-section">
          <table className="prices-table">
            <tbody>
              {prices.map((price, index) => (
                <tr key={index}>
                  <td>{price.type}</td>
                  <td>${price.price.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="train-results">
        {showSnarkyMessage && (
          <p className="snarky-message">
            Select an origin and destination to see train schedules.
          </p>
        )}
        {!showSnarkyMessage && availableTrains.length === 0 && (
          <p>No trains available for the selected route.</p>
        )}
        {availableTrains.length > 0 && (
          <ul>
            {availableTrains.map((trainGroup, index) => (
              <li key={index}
                ref={(el) => (trainRefs.current[index] = el)}
                className="train-item">
              <div className="train-plan-details">
                  {trainGroup.length === 1 ? (
                    <div>
                      <div className="train-id">
                        {formatTime(trainGroup[0].departureTime)} - {formatTime(trainGroup[0].arrivalTime)}
                      </div>
                      <div className="train-times">
                        Train {trainGroup[0].trainId} -{" "}
                        {calculateDuration(trainGroup[0].departureTime, trainGroup[0].arrivalTime)}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="train-id">
                        {formatTime(trainGroup[0].departureTime)} - Train{" "}
                        {trainGroup[0].trainId}
                      </div>
                      <div className="train-times">
                        Arrives at Diridon:{" "}
                        <strong>
                          {formatTime(trainGroup[0].arrivalTime)} (
                          {calculateDuration(trainGroup[0].departureTime, trainGroup[0].arrivalTime)}
                          )
                        </strong>
                      </div>
                      <div className="train-id">Transfer to Train {trainGroup[1].trainId}</div>
                      <div className="train-times">
                        Departs: <strong>{formatTime(trainGroup[1].departureTime)}</strong>
                      </div>
                      <div className="train-times">
                        Arrives:{" "}
                        <strong>
                          {formatTime(trainGroup[1].arrivalTime)} (
                          {calculateDuration(trainGroup[1].departureTime, trainGroup[1].arrivalTime)}
                          )
                        </strong>
                      </div>
                      <div className="train-times">
                        Layover at Diridon:{" "}
                        <strong>
                          {calculateDuration(trainGroup[0].arrivalTime, trainGroup[1].departureTime)}
                        </strong>
                      </div>
                    </div>
                  )}
              </div>
                  <div className="button-group">
                      <button
                        className="calendar-button"
                        onClick={() => {
                      const shareUrl = trainGroup.length === 1
                      ? `https://caltrain.live/#/${trainGroup[0].trainId}`
                      : `https://caltrain.live/#/${trainGroup[0].trainId} and https://caltrain.live/#/${trainGroup[1].trainId}`;
                    const shareText =
                      trainGroup.length === 1
                        ? `I'm taking Caltrain ${trainGroup[0].trainId} departing ${
                            trainGroup[0].departureStopName || "Unknown"
                          } at ${formatTime(trainGroup[0].departureTime)} and arriving in ${
                            trainGroup[0].arrivalStopName || "Unknown"
                          } at ${formatTime(trainGroup[0].arrivalTime)}. Follow along: ${shareUrl}`
                        : `I'm on train ${trainGroup[0].trainId} departing ${
                            trainGroup[0].departureStopName || "Unknown"
                          } at ${formatTime(trainGroup[0].departureTime)}, transferring to train ${
                            trainGroup[1].trainId
                          } at ${trainGroup[0].arrivalStopName}, and arriving at ${
                            trainGroup[1].arrivalStopName || "Unknown"
                          } at ${formatTime(trainGroup[1].arrivalTime)}. Follow along at ${shareUrl}`;

                    
                    if (navigator.share) {
                      navigator
                        .share({
                          title: "Caltrain Trip Details",
                          text: shareText
                        })
                        .then(() => console.log("Share successful"))
                        .catch((error) => console.error("Error sharing", error));
                    } else {
                        navigator.clipboard.writeText(`${shareText}\n${shareUrl}`);
                        alert("Details copied to clipboard!");
                    }
                          closeModal();
                        }}
                      >
                        <span className="material-symbols-outlined">ios_share</span>
                        
                      </button>

              {/* "More" Button */}
              <button
                className="more-button"
                onClick={() =>
                  openModal(
                    <div className="modal-buttons">
                      <button
                        className="modal-button"
                        onClick={() => {
                    const eventTitle =
                      trainGroup.length === 1
                        ? `Caltrain #${trainGroup[0].trainId}`
                        : `Caltrain #${trainGroup[0].trainId} + Transfer to Train #${trainGroup[1].trainId}`;

                    const description =
                      trainGroup.length === 1
                        ? `Departing ${trainGroup[0].departureStopName || "Unknown"} at ${formatTime(
                            trainGroup[0].departureTime
                          )} and arriving at ${
                            trainGroup[0].arrivalStopName || "Unknown"
                          } at ${formatTime(trainGroup[0].arrivalTime)}.`
                        : `Departing ${trainGroup[0].departureStopName || "Unknown"} at ${formatTime(
                            trainGroup[0].departureTime
                          )}, transferring to train ${trainGroup[1].trainId} at Diridon, and arriving at ${
                            trainGroup[1].arrivalStopName || "Unknown"
                          } at ${formatTime(trainGroup[1].arrivalTime)}.`;

                    // Function to convert time to Google Calendar format
                    const formatToCalendarTime = (time, date) => {
                      const [hours, minutes, seconds] = time.split(":").map(Number);
                      const eventDate = new Date(date);
                      eventDate.setHours(hours, minutes, seconds || 0); // Set time
                      return eventDate.toISOString().replace(/[-:]|(\.\d{3})/g, ""); // Format as YYYYMMDDTHHmmssZ
                    };

                    // Extract the start and end times
                    const startTime = formatToCalendarTime(trainGroup[0].departureTime, new Date());
                    const endTime = formatToCalendarTime(
                      trainGroup.length === 1
                        ? trainGroup[0].arrivalTime
                        : trainGroup[1].arrivalTime,
                      new Date()
                    );

                    // Construct the calendar URL
                    const calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
                      eventTitle
                    )}&details=${encodeURIComponent(
                      description
                    )}&dates=${startTime}/${endTime}`;

                    // Open the calendar link
                    window.open(calendarUrl, "_blank");
                          closeModal();
                        }}
                      >
                        <span className="material-symbols-outlined">calendar_month</span>
                        Google Calendar
                      </button>
                      <button
                        className="modal-button"
                        onClick={() => {
                    const eventTitle =
                      trainGroup.length === 1
                        ? `Caltrain #${trainGroup[0].trainId}`
                        : `Caltrain #${trainGroup[0].trainId} + Transfer to Train ${trainGroup[1].trainId}`;
                    const description =
                      trainGroup.length === 1
                        ? `Departing ${trainGroup[0].departureStopName || "Unknown"} at ${formatTime(
                            trainGroup[0].departureTime
                          )} and arriving at ${
                            trainGroup[0].arrivalStopName || "Unknown"
                          } at ${formatTime(trainGroup[0].arrivalTime)}.`
                        : `Departing ${trainGroup[0].departureStopName || "Unknown"} at ${formatTime(
                            trainGroup[0].departureTime
                          )}, transferring to train ${trainGroup[1].trainId} at Diridon, and arriving at ${
                            trainGroup[1].arrivalStopName || "Unknown"
                          } at ${formatTime(trainGroup[1].arrivalTime)}.`;

                    const formatToICSDate = (time, date) => {
                      const [hours, minutes, seconds] = time.split(":").map(Number);
                      const eventDate = new Date(date);
                      eventDate.setHours(hours, minutes, seconds || 0);
                      return eventDate.toISOString().replace(/[-:]|(\.\d{3})/g, "");
                    };

                    const startTime = formatToICSDate(trainGroup[0].departureTime, new Date());
                    const endTime = formatToICSDate(
                      trainGroup.length === 1 ? trainGroup[0].arrivalTime : trainGroup[1].arrivalTime,
                      new Date()
                    );

                    const icsContent = `BEGIN:VCALENDAR
              VERSION:2.0
              CALSCALE:GREGORIAN
              BEGIN:VEVENT
              SUMMARY:${eventTitle}
              DESCRIPTION:${description}
              DTSTART:${startTime}
              DTEND:${endTime}
              END:VEVENT
              END:VCALENDAR`;

                    const blob = new Blob([icsContent], { type: "text/calendar" });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${eventTitle.replace(/\s+/g, "_")}.ics`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);

                          closeModal();
                        }}
                      >
                        <span className="material-symbols-outlined">event</span>
                        Apple Calendar
                      </button>
                    </div>
                  )
                }
              >
                <span className="material-symbols-outlined">calendar_month</span>
                  </button>
                  </div>
            </li>
            ))}
          </ul>
        )}
      </div>
            {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {modalContent}
            <button className="close-button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TripPlanner;