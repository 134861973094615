import React from "react";
import { TrainRow, TrainRowMobile } from "./TrainRow";




const TrainTable = ({ direction, trains }) => {
  //const isMobile = window.innerWidth <= 768; // Check if the screen is mobile-sized
  const isMobile = false;
  return (
    <>
      {isMobile ? (
      <table>
        <thead>
          <tr className="table100-head-dir">
            <th colSpan="1">{direction}</th>
          </tr>
          <tr className="table100-head">
            <th className="column1">Train - Status - Location</th>
          </tr>
        </thead>
        <tbody>
          {trains.length > 0 ? (
            trains.map((train) => <TrainRowMobile key={train.trainId} train={train} />)
          ) : (
            <tr>
              <td colSpan="3">No trains available</td>
            </tr>
          )}
        </tbody>
      </table>
      ) : (
      <table>
        <thead>
          <tr className="table100-head-dir">
            <th colSpan="3">{direction}</th>
          </tr>
          <tr className="table100-head">
            <th className="column1">Train</th>
            <th className="column2">Status</th>
            <th className="column3">Location</th>
          </tr>
        </thead>
        <tbody>
          {trains.length > 0 ? (
            trains.map((train) => <TrainRow key={train.trainId} train={train} />)
          ) : (
            <tr>
              <td colSpan="3">No trains available</td>
            </tr>
          )}
        </tbody>
      </table>
      )}
    </>
  );
};

export default TrainTable;