import React from "react";


const Alert = () => (
  <div className="alert alert-dark" role="alert">
    <h5 className="alert-heading">Tried our new app?</h5>
    <p>
      We noticed you are on an Apple device.  Try our new app, <a href='https://apps.apple.com/us/app/caltrain-companion/id6737343619'>Caltrain Companion, available in the app store</a>!
    </p>
    <p>The app called "Caltrain Live" is not affiliated with us.</p>
  </div>
);

export default Alert;
