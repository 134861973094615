import React from "react";
import {
  useNavigate
} from "react-router-dom";


  function getMinutesAgo(recordedAtTime) {
    // Parse the recordedAtTime into a Date object
    const recordedTime = new Date(recordedAtTime);

    // Get the current time
    const now = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = now - recordedTime;

    // Convert milliseconds to minutes and round to the nearest whole number
    const minutesAgo = Math.round(differenceInMilliseconds / (1000 * 60));

    return minutesAgo;
  }


const TrainRow = ({ train }) => {
  const navigate = useNavigate();

  const getStatusClass = () => {
    if (train.holding || train.minutesLate > 10) return "redbg";
    if (train.minutesLate > 4) return "yellowbg";
    return "";
  };
  
  const minutesAgo = getMinutesAgo(train.recordedAtTime);


  const handleRowClick = () => {
    navigate(`/${train.trainId}`);
  };
  return (
    <tr onClick={handleRowClick}
      className="clickable-row"
      style={{ cursor: "pointer" }}
    >
      <td>{train.trainId}</td>
      <td className={getStatusClass()}>{train.status}{minutesAgo > 2 && (` (Data recorded ${minutesAgo} minutes ago)`)}</td>
      <td>{train.location}</td>
    </tr>
  );
};


const TrainRowMobile = ({ train }) => {
  const navigate = useNavigate();
    const handleRowClick = () => {
      navigate(`/${train.trainId}`);
    };
  // const getStatusClass = () => {
  //   if (train.holding || train.minutesLate > 10) return "red";
  //   if (train.minutesLate > 4) return "yellow";
  //   return "green";
  // };

  const minutesAgo = getMinutesAgo(train.recordedAtTime);

  return (
    <tr onClick={handleRowClick}
      className="clickable-row {getStatusClass()}"
      style={{ cursor: "pointer" }}
    >
      <td>{train.trainId} - {train.status}{minutesAgo > 2 && (` (Data recorded ${minutesAgo} minutes ago)`)} - {train.location}</td>
    </tr>
  );
};


// Group and export both components
export {
  TrainRow,
  TrainRowMobile
};