const schedTypes = {
  collegepark: "College Park",
  local: "Local",
  limited: "Limited",
  express: "Express",
  weekend: "Weekend",
  southcounty: "South County"
}


export default schedTypes;