import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css"; // Add CSS styling for the navbar

const Navbar = () => {
  const location = useLocation();

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          Caltrain Live
        </Link>
        <ul className="navbar-links">
          <li>
            <Link
              to="/"
              className={`navbar-link ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              Live Tracking
            </Link>
          </li>
          <li>
            <Link
              to="/planner"
              className={`navbar-link ${
                location.pathname.startsWith("/planner") ? "active" : ""
              }`}
            >
              Trip Planner
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;